import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Constants from '../utilities/Constants';
import Skeleton from '@mui/material/Skeleton';
import { Modal } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';


const Dummyvote = () => {

    const [loading, setLoading]     = React.useState(true);
    const [open, setOpen]           = React.useState(false);
    
    const [isclicked, setisclicked] = React.useState(false);
    const [number, setNumber]       = React.useState()
    let notaindex                   = 1;

    React.useEffect(() => {
        setLoading(false)
    }, [])

    const unvote = () => {
        new Audio('https://documents.i-vote.in/assets/error.mp3').play()
    }

    const sendWhatsapp = () => {
        let message = Constants.learnings.message
        let url     = number !== null && number ? 
                    `https://wa.me/${number.replace(/[^\w\s]/gi, "").replace(/ /g, "")}?&text=${encodeURIComponent(message)}&app_absent=0` 
                    :
                    `https://wa.me/?&text=${encodeURIComponent(message)}&app_absent=0`;
        window.open(url);
    }

    const vote = () => {
        setisclicked(true)
        new Audio('https://documents.i-vote.in/assets/beep.mp3').play()
        setTimeout(() => thanks(), 1000)
        setTimeout(() => setisclicked(false), 2000)
        setTimeout(() => openVVPT(), 5000)
    }

    const openVVPT = () => {
        setOpen(true)
        setTimeout(() => setOpen(false), 5000)
    }

    const thanks = () => new Audio('https://documents.i-vote.in/assets/thanks.mp3').play()

    const speakIntro = () => {
        if(Constants?.learnings?.info_audio) {
            new Audio(`https://documents.i-vote.in/${Constants.learnings.info_audio}`).play()
        }
    }

    const speakThanks = () => {
        speak('धन्यवाद!')
    }

    const speak = (text) => {
        // Create a SpeechSynthesisUtterance
        const utterance = new SpeechSynthesisUtterance(text);
      
        // Select a voice
        const voices    = speechSynthesis.getVoices();
        utterance.voice = voices[0]; // Choose a specific voice
        utterance.lang  = 'mr-IN'
      
        speechSynthesis.cancel()

        // Speak the text
        speechSynthesis.speak(utterance);
    }

    const details = () => {
        let arr = []
        for(let i = 1; i <= Constants.learnings.maxsr; i++) {
            if(Constants.learnings?.sr >= 5 && i > 2 && i < Constants.learnings?.sr) {
                arr[1] = '2...'
                continue;
            }
            arr.push(i)
            notaindex = i+1
        }

        return <>
            {
                arr.map(a => {
                    return Constants.learnings?.sr == a ? (
                            <Grid container spacing={0} key={a} style={{minHeight: '100px', border: '1px solid grey'}}>
                                <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: '26px', borderRight: '1px solid #001'}}>
                                    {a}
                                </Grid>
                                <Grid item xs={4} style={{alignItems: 'center', display:'flex', justifyContent: 'center', fontWeight: '600', fontSize: '12px'}}>
                                    {Constants.learnings.namemr} <br /> {Constants.learnings.name}
                                </Grid>
                                <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                                    <img src={Constants.constants.document_url + '/' + Constants.learnings.photo} alt={Constants.learnings.name} width={'50'} /> 
                                </Grid>
                                <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center', borderRight: '1px solid #001'}}>
                                    <img src={Constants.constants.document_url + '/' + Constants.party[0].party_symbol_image} alt={Constants.learnings.name} width={'50'}/> 
                                </Grid>
                                <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center', }}>
                                    <img src={isclicked ? 'https://documents.i-vote.in/assets/redleftarrow.png' : 'https://documents.i-vote.in/assets/leftarrow.png'} alt={Constants.learnings.name} width={'20'}/> 
                                </Grid>
                                <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                                    <Button variant="contained" size="large" onClick={vote}>
                                    {Constants.languages[Constants.selectedLanguage].vote.btn.click}
                                    </Button>
                                </Grid>
                            </Grid>
                    ) : (
                            <Grid container spacing={0} key={a} style={{minHeight: '60px', border: '1px solid #001'}}>
                                <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8', borderRight: '1px solid #001'}}>
                                    {a}
                                </Grid>
                                <Grid item xs={4} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8'}}>
                                    
                                </Grid>
                                <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8'}}>
                                    {/* <img src='https://i-vote.in/images/i-vote-logo.png' alt={`Constants.learnings - ${a}`} width={'50'} />  */}
                                </Grid>
                                <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8', borderRight: '1px solid #001'}}>
                                    {/* <img src='https://i-vote.in/images/i-vote-logo.png' alt={`Symbol Constants.learnings - ${a}`} width={'30'}/>  */}
                                </Grid>
                                <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center', }}>
                                    <img src='https://documents.i-vote.in/assets/leftarrow.png' alt={Constants.learnings.name} width={'20'}/> 
                                </Grid>
                                <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                                    <Button variant="contained" size="large" onClick={unvote}>
                                    {/* {Constants.languages[Constants.selectedLanguage].vote.btn.click} */}
                                    </Button>
                                </Grid>
                            </Grid>
                    )
                })  
            }
        </>
    }

    return (
        <>
            <Card style={{padding: '0'}}>
            
                <CardHeader title={<>
                        <p>{Constants.learnings?.title}</p>
                        <span>डिजिटल डमी मतदान यंत्र (EVM) (Only for education purpose)</span>
                    </>
                } 
                subheader={
                    <>      
                        {
                            Constants.learnings?.info
                        }
                </>}
                sx={{ textAlign: 'center' }} className='bg-brand' />

                <CardContent
                    sx={{ padding: '0' }}
                >
                    <Grid container spacing={0} style={{marginBottom: '10px', padding:0}}>
                        <Grid item xs={12} style={{alignItems: 'center', display:'flex', justifyContent: 'center', marginTop:'10px'}}>
                            <TextField
                                sx={{}}
                                className='mb-2'
                                label={Constants.languages[Constants.selectedLanguage].vote.lbl.mobileNo}
                                id="outlined-size-small-mobile-no"
                                size="small"
                                fullWidth
                                value={number}
                                onChange={e => setNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} style={{alignItems: 'center', display:'flex', justifyContent: 'center', marginTop:'10px'}}>
                            <LoadingButton 
                                className=''
                                variant="contained" 
                                size="small"
                                loading={loading}
                                color="success"
                                fullWidth
                                onClick={sendWhatsapp}
                            >
                                <span>
                                    {Constants.languages[Constants.selectedLanguage].vote.btn.sendWhatsapp}
                                </span>
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} style={{alignItems: 'center', display:'flex', justifyContent: 'center', marginTop:'10px'}}>
                            <LoadingButton 
                                className=''
                                variant="contained" 
                                size="small"
                                loading={loading}
                                color="error"
                                fullWidth
                                onClick={speakIntro}
                            >
                                <span>
                                    {Constants.languages[Constants.selectedLanguage].vote.btn.moreInfo}
                                </span>
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{minHeight: '60px', border: '1px solid #001', fontWeight: '500', fontSize: '10px'}}>
                        <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center', borderRight: '1px solid #001'}}>
                            Sr.
                        </Grid>
                        <Grid item xs={4} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                            Name of Candidate
                        </Grid>
                        <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                            Photo
                        </Grid>
                        <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center', borderRight: '1px solid #001'}}>
                            Symbol
                        </Grid>
                        <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center', borderRight: '1px solid #001'}}>
                            Bulb
                        </Grid>
                        <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                            Button
                        </Grid>
                    </Grid>

                    {
                        details()
                    }
                
                    <Grid container spacing={0} style={{minHeight: '60px', border: '1px solid #001'}}>
                        <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8', borderRight: '1px solid #001'}}>
                            {notaindex}
                        </Grid>
                        <Grid item xs={4} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8'}}>
                            NOTA
                        </Grid>
                        <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8'}}>
                            {/* <img src='https://i-vote.in/images/i-vote-logo.png' alt={`NOTA}`} width={'50'} />  */}
                        </Grid>
                        <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center', background: '#E8E8E8', borderRight: '1px solid #001'}}>
                            {/* <img src='https://i-vote.in/images/i-vote-logo.png' alt={`Symbol NOTA`} width={'30'}/>  */}
                        </Grid>
                        <Grid item xs={1} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                            <img src='https://documents.i-vote.in/assets/leftarrow.png' alt={'NOTA'} width={'20'}/> 
                        </Grid>
                        <Grid item xs={2} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                            <Button variant="contained" size="large"  onClick={unvote}>
                                {/* {Constants.languages[Constants.selectedLanguage].vote.btn.click} */}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>

            </Card>
            <Modal
                open={open}
            >
                <div style={{width: '90%', maxWidth: '400px', margin: '20% auto', background:'#fff'}}>
                    {/* <img src='https://documents.i-vote.in/assets/slip145.png' /> */}
                    <p style={{alignItems: 'center', display:'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: '36px', padding: '20px', margin: 0, borderBottom: '1px solid grey' }}>
                        वीवीपैट / VVPT
                    </p>
                    <Grid container spacing={0} style={{minHeight: '100px', borderBottom: '1px solid grey'}}>
                        <Grid item xs={4} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                            <img src={Constants.constants.document_url + '/' + Constants.learnings.photo} width={'90px'}/>
                        </Grid>
                        <Grid item xs={8} style={{alignItems: 'center', display:'flex', justifyContent: 'left'}}>
                            <p>
                                {Constants.learnings.namemr}
                                <br />
                                {Constants.learnings.name}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} style={{minHeight: '100px', borderBottom: '1px solid grey'}}>
                        <Grid item xs={4} style={{alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                            <img src={Constants.constants.document_url + '/' + Constants.party[0].party_symbol_image} width={'50px'}/>
                        </Grid>
                        <Grid item xs={8} style={{alignItems: 'center', display:'flex', justifyContent: 'left'}}>
                            <p>
                                {Constants.party[0].name}
                            </p>
                        </Grid>
                    </Grid>
                    <p style={{alignItems: 'center', display:'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: '16px', padding: '20px', margin: 0 }}>
                        {Constants.languages[Constants?.selectedLanguage].vote.lbl.finalMsg}
                    </p>
                </div>
            </Modal>

        </>
            
    )
}

export default Dummyvote;
